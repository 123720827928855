

.ti-btn {
  margin: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border:0px;
  outline: 0;
  overflow: hidden;
  display: inline-block;
  position: relative;
  user-select: none;
  box-shadow: 0 0 0 0 rgba(white, 0.5);
  transition: box-shadow 150ms ease-out;
}



.ti-btn:focus {
  box-shadow: 0 0 0 1px rgba(rgba(255, 255, 255, 0.534), 0.5);
}

.ripple {
  background-color: rgba(255, 255, 255, 0.479);
  width: .20rem;
  height: .20rem;
  position: absolute;
  border-radius: 100px;
  transform: translateX(-100%) translateY(-100%);
  mix-blend-mode: screen;
  animation: ripple 1250ms ease-out forwards, fade 1500ms ease-out forwards;
}

@keyframes ripple {
  0% {
    transform: translate(-100%, -100%);
  }
  80% {
    transform: translate(-100%, -100%) scale(10);
  }
  100% {
    transform: translate(-100%, -100%) scale(10);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ColorFilter {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

$thumb-size: 16px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
  &:disabled {
    cursor: not-allowed;
    background-color: lightgray;
  }
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: lightgray;
  }
}


.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1.6rem;
  height: calc(#{$thumb-size} + 1.6rem);
}

.input-wrapper {
  width: calc(100% + #{$thumb-size});
  margin: 0 calc(#{$thumb-size} / -2);
  position: absolute;
  height: $thumb-size;
}

.control-wrapper {
  width: 100%;
  position: absolute;
  height: $thumb-size;
}

.input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
}

.input:disabled::-webkit-slider-runnable-track {
  background-color: lightgray;
}

.input:disabled::-moz-range-track {
  background-color: lightgray;
}

.input:disabled::-ms-track {
  background-color: lightgray;
}

.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  border-radius: 3px;
  background: lightgrey;
}

.inner-rail {
  position: absolute;
  height: 100%;
  background: #3b71ca;
  opacity: 0.5;
}

.control {
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  position: absolute;
  background: #3b71ca;
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
}

.control:disabled{
  background-color: lightgray;

}

input:disabled::-moz-range-progress {
  background-color: #246168 !important;
  cursor: not-allowed;
}

input:disabled::-moz-range-track {
  background-color: #9a905d !important;
  cursor: not-allowed;
}
