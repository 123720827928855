.CustomDropdown:hover{
    
    background-color: #3b71ca!important;
    color: black!important;

}

.CustomDropdownRight{
    border-right: 0px!important;
}

.CustomDropdownLeft{
    border-left: 0px!important;
}

.CustomDropdown{
    
    color: black!important;
    background-color: rgb(255, 255, 255)!important;
}

.CustomDropdownItem{
    color: black!important;
    background-color: rgb(255, 255, 255)!important;
}


.CustomDropdownItem:hover{
    color: black!important;
    background-color: rgb(245, 242, 242)!important;
}