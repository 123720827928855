.little-square-table {

}

.little-square-table .little-square-inner {
    overflow: hidden;
    background-color: white;
}

.little-square-inner:hover, .little-square-inner.force-hover {
    /*filter: brightness(0.8);*/
    filter: contrast(0.8);
}
