.MapManager{
    max-height: 70vh;
    display:flex;
    flex-direction:row;
    margin: 10px;
    justify-content: center;
    min-width: max-content;
}

.MapTypeButtonContainer{
    margin-top:65vh;
}

.MapTypeButtonContainer button{
    width: 200px;
}


.Selector{
    display:flex;
    flex-direction:column;
    margin: 10px;
    width: fit-content;
    justify-content: space-evenly
}   

