.highwaylistContainer {
    display: flex;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 350px;
    height: 70vh;
}
  
.highwaylist-group {
    flex: 1; 
    max-height: 100vh; 
    width: fit-content;

  }
  
  .highwaylist-group-item {
    cursor: pointer; 
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 150px;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom:5px;
    height: 50px ;
    margin-top:10px;
}

.highwaylist-group-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1.1); 
}
.selected-highway {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
  }