@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2 {
  margin-bottom: 18px;
  font-family: "Barlow Condensed";
}

.manual-scheduler-buttons {
  border: 0px solid black;
}

/* grid layou style */

.nav-link {
  color: #0061a1
}


.scenario-single-label {
  display: inline-block;
  padding: 6px 25px;
  font-size: 17pt;
  /*border: 3px solid #0061a1;*/
  background-color: #eeeeef;
  margin-bottom: -3px;
  /*margin-right: -3px;*/
  user-select: none;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}

.scenario-single-label:hover {
  background-color: #d9d8d8;
}

.scenario-single-label.scenario-selected {
  border-bottom: 3px solid #0061a1;
  background-color: #9dbcd5;
}

.scenario-navigator {
  border-bottom: 3px solid white;
  padding-left: 10px;
  /*border-top: 3px solid #0061a1;*/
}