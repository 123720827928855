.react-grid-item:not(.special-grid-item){
    background-color: white;
    border-radius: 0;
    border: 1px solid #c3c4c7;
}

.rebus-grid-element-header {
    background-color: white;
    border-bottom: 1px solid #c3c4c7;
    line-height: 35px;
    padding-right: 10px;
    padding-left: 10px;
}

.rebus-grid-element-body {
    padding: 10px;
}