table.conciTable {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed; /* Aggiunto per fissare la larghezza delle celle */
}

table.conciTable tr:first-child td {
    border: 1px solid grey;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 70%;
    text-align: center;
    font-family: monospace;
}

table.conciTable tr:not(:first-child) td {
    border: 1px solid grey;
    height: 10px;
    width: 10px; /* Imposta una larghezza fissa per tutte le celle */
}

table.conciTable tr:not(:first-child) td:first-child {
    line-height: 1pt;
    font-size: 7pt;
    font-family: monospace;
    text-align: center;
}

table.conciTable td.param-cda_sgg_slo-4 {
    background-color: #FF7C80;
}
table.conciTable td.param-cda_sgg_slo-3 {
    background-color: #FFC000;
}
table.conciTable td.param-cda_sgg_slo-2 {
    background-color: #FFE699;
}
table.conciTable td.param-cda_sgg_slo-1 {
    background-color: #92D050;
}

table.conciTable td.param-tunnel_rehab-IMS {
    background-color: #00B4FF;
}

table.conciTable td.param-tunnel_rehab-IRS {
    background-color: #0044ce;
}

table.conciTable td.param-tunnel_rehab-TRS {
    background-color: #FFE600;
}