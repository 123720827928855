.cont-new-sim{
    width: max-content;
    height: max-content;
    margin-right:100px;
    max-width: max-content;
    margin-left: 100px;
}

.cont-simulatio-settings{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}